import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { FaRegCalendarAlt, FaUser, FaMap } from 'react-icons/fa';

import Content, { HTMLContent } from '../components/Content';
import { NotFound } from '../pages/404';
import SEO from '../components/SEO';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Layout from '../components/Layout';
import { HeroContainer } from '../components/Containers';
import mediaSizes from '../components/MediaSizes';


const DayList = styled.ol`
    list-style: none;
    margin-left: 20px !important;

    li {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid grey;
    }

    li:before {
        content: '>';
        padding-right: 8px;
        margin-left: -18px;
    }
`;

const Gallery = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(${props => props.rows}, 10vw);
    grid-gap: 10px;

    @media (max-width: ${mediaSizes.tablet}px) {
        grid-template-rows: repeat(${props => props.rows}, 30vw);
    }
`;

export const CourseTemplate = ({
    title,
    active,
    startDate,
    endDate,
    location,
    instructor,
    description,
    featuredcourse,
    soldout,
    cost,
    days,
    courseHighlights,
    featuredimage,
    add,
    content,
    contentComponent,
}) => {
    const CourseContent = contentComponent || Content
    console.log(active)
    if (!active) {
        return <NotFound />
    }
    return (
        <React.Fragment>
            <HeroContainer title="Courses" image="/img/courses.jpg" />
            <section className="section">
                <div className="container">
                    <div className="content">
                        <div className="columns">
                            <div className="column is-8">
                                <h2 className="has-text-primary is-size-2">
                                    {title}
                                </h2>
                                <p>
                                    <FaUser /> With{' '}
                                    <strong>{instructor}</strong>
                                </p>
                                <p>
                                    <FaMap /> At {location}
                                </p>
                                <p>
                                    <FaRegCalendarAlt /> {startDate} - {endDate}
                                </p>
                                <p>{description}</p>
                            </div>
                            <div className="column is-4">
                                {featuredimage && (
                                    <PreviewCompatibleImage
                                        className=""
                                        imageInfo={{
                                            image: featuredimage,
                                            alt: `featured image thumbnail for ${title}`
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-4">
                                <h2 style={{ color: 'green' }}>
                                    Course Highlights
                                </h2>
                                <ol>
                                    {courseHighlights.map(highlight => (
                                        <li type="disc">
                                            {highlight.highlight}
                                        </li>
                                    ))}
                                </ol>
                            </div>
                            <div className="column is-8">
                                {add && (
                                    <Gallery rows={Math.ceil(add.length / 2)}>
                                        {add.map(additionalImage => (
                                            <PreviewCompatibleImage
                                                className=""
                                                imageInfo={{
                                                    image: additionalImage.image,
                                                    alt: `featured image thumbnail for ${title}`
                                                }}
                                            />
                                        ))}
                                    </Gallery>
                                )}
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-12">
                                <h2>Day by Day Workshop Schedule</h2>
                                <DayList type="none">
                                    {days.map(day => (
                                        <li>
                                            Day {day.day}: {day.dayDescription}
                                        </li>
                                    ))}
                                </DayList>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <h2 style={{ color: 'red' }}>
                                    Course Cost: {cost}
                                </h2>
                            </div>
                        </div>
                        <CourseContent content={content} />
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

const Course = ({ data }) => {
    const { markdownRemark: post } = data;
    return (
        <Layout>
            <SEO
                title={`${post.frontmatter.title} - Follow Your Senses In Tuscany`}
                image="/img/courses.jpg"
                description={post.frontmatter.description}
            />
            <CourseTemplate
                {...post.frontmatter}
                content={post.html}
                contentComponent={HTMLContent}
                helmet={
                    <Helmet titleTemplate="%s | Blog">
                        <title>{`${post.frontmatter.title} - Follow Your Senses In Tuscany`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
            />
        </Layout>
    );
};

export default Course;

export const pageQuery = graphql`
    query CourseById($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                active
                startDate(formatString: "DD MMMM")
                endDate(formatString: " DD MMMM, YYYY")
                location
                description
                featuredcourse
                soldout
                cost
                days {
                    day
                    dayDescription
                }
                instructor
                courseHighlights {
                    highlight
                }
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                add {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
