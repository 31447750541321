import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import SEO from '../components/SEO';
import ContactForm from '../pages/contact';
import Layout from '../components/Layout';
import Download from '../components/Download';
import PayUButton from '../components/PayUButton';
import PayUEURButton from '../components/PayUEURButton';

export const ContactNewPageTemplate = ({ title }) => (
    <section className="section">
        <div className="container">
            <div className="content">
                <h1>{title}</h1>
                <ContactForm />
                <h2>Contact Info</h2>
                <p>FOLLOW YOUR SENSES K&D LENART SP.K.</p>
                <p>Founder: KAROLINA LENART AND DAWID LENART</p>
                <p>Phone: 0039-345-580-9974 or 0048-880-876-413</p>
                <p>Email: followyoursensesintuscany@gmail.com</p>
                <h2>
                    Follow the links for privacy policy and payment terms and
                    conditions.
                </h2>
                <ul>
                    <li>
                        <Link to="privacy-policy">Privacy Policy</Link>
                    </li>
                </ul>
                <p>
                    Before booking, please contact Karolina:
                    followyoursensesintuscany@gmail.com to confirm that space is
                    available for required course.
                </p>

                <p>
                    If the space is available, please e-mail the completed
                    booking form. To reserve your spot the $1000/€1000 deposit its
                    obligated
                </p>
            </div>
        </div>
    </section>
);

ContactNewPageTemplate.propTypes = {
    title: PropTypes.string.isRequired
};

const ContactNewPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description}
            />
            <ContactNewPageTemplate title={post.frontmatter.title} />
        </Layout>
    );
};

ContactNewPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default ContactNewPage;

export const pageQuery = graphql`
    query ContactNewPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                description
            }
        }
    }
`;
