import React from 'react';
import { Link, graphql } from 'gatsby';

import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import CoursesList from './components/CoursesList';
import { Collage } from '../../components/Image';
import { HeroContainer } from '../../components/Containers';

const CoursesPage = ({ data }) => (
    <Layout>
        <SEO
            title="Courses - Follow Your Senses In Tuscany"
            image="/img/courses.jpg"
            description="Our courses for 2025/2026"
        />
        <HeroContainer title="2025/2026 Courses" image="/img/courses.jpg" />
        <section className="section">
            <div className="container">
                <div className="content">
                    <h2>
                        Our main goal is to offer all-inclusive, high quality
                        rehabilitation / art therapy / art / wellbeing retreats
                        in a beautiful and inspiring environment. We believe
                        that you should come away from our retreats and
                        workshops feeling refreshed, inspired and with many new
                        friends from around the world!
                    </h2>
                    <p>
                        Drawing, sculpting, painting, etc. are great ways to
                        lower your overall stress levels and make you feel much
                        better mentally. Tuscany is so picturesque it could
                        bring out the artist in you! Research studies have
                        discovered that viewing beautiful art can also be good
                        for your mental health improving thinking and empathy,
                        and even releasing dopamine into the brain, a chemical
                        related to feelings of love and pleasure. Our doctors ,
                        teachers and our therapists are here to ensure you have
                        what you need in every aspect of your stay and are as
                        inspired and productive as you wish to be. Our 2025/2026
                        are available for You!
                    </p>
                    <p>
                        Booking: Before booking, please contact Karolina:
                        FollowYourSensesInTuscany@gmail.com to confirm that
                        space is available for the required course.
                    </p>
                    <p>
                        If the space is available, please e-mail the completed
                        booking form. To reserve your spot the $1000/€1000 deposit
                        its obligated.
                    </p>
                    <div style={{ textAlign: 'center' }}>
                        <Link className="btn" to="/contact-us">
                            Book Now
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="content">
                    <CoursesList />
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="content">
                    <Collage
                        collage={data.markdownRemark.frontmatter.coursesCollage}
                    />
                </div>
            </div>
        </section>
    </Layout>
);

export default CoursesPage;

export const pageQuery = graphql`
    query CoursesPage {
        markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
            frontmatter {
                coursesCollage {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
